import { getDataMapper, mapField } from "@/shared";

export const mapPaymentFiltersToServer = getDataMapper({
    from: mapField("range", (item) => item.from),
    to: mapField("range", (item) => item.to),
    tariff_id: mapField("tariff"),
    period: mapField("period"),
    is_completed: mapField("isCompleted", Number),
    is_not_completed: mapField("isNotCompleted", Number),
});
